import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn from '../../../layouts/TemplateColumn';
import { PageHeader, PageIntro, AnchorLinks, PageSection, TriageList, PrimaryCallOut, FilterProvider } from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions for KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `keytruda adverse reactions, pembrolizumab adverse reactions`,
    description: `Health care professionals can review incidence and management of selected immune-mediated adverse reactions to KEYTRUDA® (pembrolizumab) and study-specific select adverse reactions.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/","@type":"MedicalWebPage","name":"Severe and Fatal Immune-Mediated Adverse Reactions","description":"KEYTRUDA is a monoclonal antibody that belongs to a class of drugs that bind to either the programmed death receptor-1 (PD⁠-⁠1) or the programmed death ligand 1 (PD⁠-⁠L1), blocking the PD⁠-⁠1/PD⁠-⁠L1 pathway, thereby removing inhibition of the immune response, potentially breaking peripheral tolerance and inducing immune-mediated adverse reactions."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Study-Specific Selected Adverse Reaction Data'}
]

const Page = ({ location }) => {

    return (
        <FilterProvider>
            <DefaultLayout jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
                <div data-template-name="template-b">
                    {/* Begin Intro Column */}
                    <TemplateColumn id="template-b-intro" maxWidth={824}>
                        <PageHeader title="Selected Adverse Reactions" />
                        <PageSection bgColor='cloud'  title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <PageIntro children={globalSafetyBlock} />
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Intro Column */}

                    {/* Begin Main Column */}
                    <TemplateColumn id="template-b-main">

                        <GlobalSafetyData />

                    <PageSection title={anchorLinkModelData[2].label}>
                        <TriageList listType='safety' />
                    </PageSection>
                    <PageSection bgColor="cloud">
                        <PrimaryCallOut
                            title="Not seeing the type of tumor you are looking for?"
                            content="For more information, including a complete list of indications KEYTRUDA is approved to treat, please see the full Prescribing Information for KEYTRUDA."
                            secondaryAction={{ label: 'View Prescribing Information', href: '/prescribing-information/', external: true, target: '_blank' }}
                        />
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    </FilterProvider>
    );
};

export default Page;
